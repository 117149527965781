import React, { useEffect, useState } from 'react';
import Accordion from '../../components/Accordion';
import { PythonQuestions } from '../../data/InterviewQuestions';
import { FaBookOpen } from "react-icons/fa";
import axios from 'axios';
import { useParams } from 'react-router-dom';

interface Question {
  id: string;
  topicId: string;
  topic: string;
  title: string;
  content: string;
}

function InterviewQuestions() {
  const [uniqueTopics, setUniqueTopics] = useState<Question[]>([]); // Defining state with string array for topics
  const [selectTopic, setSelectTopic] = useState(PythonQuestions[0].id);
  const [topicWiseQuestions, setTopicWiseQuestions] = useState<Question[]>([]);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  let { id } = useParams();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    const fetchData = async () => {
      await axios.get(`https://api.zoneofed.com/v1/interview-questions?lang=${id}`).then((res) => {

        setTopicWiseQuestions(res.data.data.questions)
      }).catch(err => console.log(err))
    }
    fetchData();
  }, [])

  // useEffect(() => {
  //   const seenTopicIds = new Set();
  //   const result = PythonQuestions.filter(question => {
  //     if (!seenTopicIds.has(question.topicId)) {
  //       seenTopicIds.add(question.topicId);
  //       return true; // Keep this question, it's the first time this topicId is seen
  //     }
  //     return false; // Skip this question as it's a duplicate topicId
  //   })
  //   filterQuestions(PythonQuestions[0].topicId);
  //   setUniqueTopics(result)
  // }, []);

  const filterQuestions = async (currentTopicId: string) => {
    const result = PythonQuestions.filter(({ topicId }) => {
      return topicId === currentTopicId
    });
    await setTopicWiseQuestions(result)
  }

  const handleTopicSelect = (id: string, topicId: string, topic: string) => {
    setSelectTopic(id);
    setIsSidebarOpen(false);
    filterQuestions(topicId);
  }

  return (
    <div className="interview_questions">
      {/* <div className={`interview_questions_sidebar ${isSidebarOpen ? 'interview_questions_sidebar--open' : ''}`}>
        <ul>
          {uniqueTopics.length > 0 &&
            uniqueTopics.map(({ id, topicId, topic }, index) => (
              <li
                className={(id === selectTopic) ? 'active_topic' : ''}
                key={`${topic}_${index}`}
                onClick={() => handleTopicSelect(id, topicId, topic)}
              >
                {topic}
              </li>
            ))}
        </ul>
      </div> */}
      <div className="interview_questions_container">
        {/* <div className='interview_questions_container_bookIcon'>
          <FaBookOpen size={30} color='#1DB954' onClick={toggleSidebar} />
        </div> */}
        {topicWiseQuestions.map(({ question, answer
        }: any, index) => (
          <Accordion id={`${question}_${index}`} title={question} content={answer} key={question} />
        ))}
      </div>
    </div>
  );
}

export default InterviewQuestions;
