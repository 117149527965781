import userImg from '../../assets/user.png';
import Button from '../../components/Button'
import Benefits from './Benefits';
import FeatureCourses from './FeatureCourses';
import Footer from './Footer';
import Values from './Values';

function Home() {
    return (
        <div>
            <div className='home_page'>
                <div className='home_page_container'>
                    <div className='home_page_container_content'>
                        <h1>Learn to <span>Code</span></h1>
                        <p>The most efficient and supportive way for you to learn in-demand skills, get hired, and advance your career.</p>
                        <Button labelText='join now' withIcon />
                    </div>
                    <div className='home_page_container_image'>
                        <img src={userImg} alt='userImg' />
                    </div>
                </div>
            </div>
            <Values />
            <FeatureCourses />
            <Benefits />
            <Footer />
        </div>
    )
}

export default Home