import { Key, useCallback, useEffect, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Button from '../../components/Button';
import CustomModal from '../../components/CustomModal';
import { IoMdOpen } from "react-icons/io";
import CelebrationModal from '../../components/CustomModal';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import axios from 'axios';
import Loader from '../../components/Loader';

interface Answer {
  id: string;
  text: string;
}

interface Question {
  question: string;
  topic: string;
  options: Answer[];
  correctAnswer: string;
  selectedAnswer?: Answer;
}

interface QuizData {
  data: {
    quiz: Question[];
  };
}

const KnowledgeCheck: React.FC = () => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<Question | null>(null);
  const [uniqueTopics, setUniqueTopics] = useState<string[]>([]);
  const [topicWiseQuestions, setTopicWiseQuestions] = useState<Question[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [score, setScore] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openTopicsModal, setOpenTopicsModal] = useState<boolean>(false);
  const [topicName, setTopicName] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(true);

  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchDData = async () => {
      try {
        const response = await axios.get<QuizData>(`https://api.zoneofed.com/v1/quiz`);
        const quizData = response.data?.data?.quiz || [];
        setQuestions(quizData);

        if (quizData.length) {
          const topics = quizData.map(q => q.topic);
          const uniqueTopicsArray = Array.from(new Set(topics));
          setUniqueTopics(uniqueTopicsArray);
          loadTopicQuestions(uniqueTopicsArray[0], quizData);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchDData();
  }, []);

  const loadTopicQuestions = useCallback((topic: string, ques: Question[] = []) => {
    setLoading(true);
    setTopicName(topic)
    const topicQuestions = ques.filter(q => q.topic === topic);
    setTopicWiseQuestions(topicQuestions);
    setCurrentQuestion(topicQuestions[0]);
    setCurrentQuestionIndex(0)
    setOpenTopicsModal(false);
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, []);

  const onNext = useCallback(() => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    setCurrentQuestion(topicWiseQuestions[currentQuestionIndex + 1]);
  }, [currentQuestionIndex, topicWiseQuestions]);

  const onPrev = useCallback(() => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    setCurrentQuestion(topicWiseQuestions[currentQuestionIndex - 1]);
  }, [currentQuestionIndex, topicWiseQuestions]);

  const onSubmit = useCallback(() => {
    const newScore = topicWiseQuestions.reduce(
      (acc, question) => acc + (question.selectedAnswer?.id === question.correctAnswer ? 1 : 0),
      0
    );
    setScore(newScore);
    setOpenModal(true);
  }, [topicWiseQuestions]);

  const onSelectAnswer = useCallback((answer: Answer) => {
    setTopicWiseQuestions((prevQuestions) =>
      prevQuestions.map((question, index) =>
        index === currentQuestionIndex ? { ...question, selectedAnswer: answer } : question
      )
    );
  }, [currentQuestionIndex]);

  const onOpenSidebar = useCallback(() => {
    setOpenTopicsModal(true);
  }, []);

  const handleContinue = () => {
    loadTopicQuestions(topicName, questions)
    setOpenModal(false)
  }

  const percentage = (score / topicWiseQuestions.length) * 100;

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="quizz">
      <CelebrationModal isOpen={openModal} onClose={() => setOpenModal(false)} animation={true}>
        <div className="content">
          <div className='content_score'>Your score</div>
          <div className="progress-circle">
            <CircularProgressbar
              value={percentage}
              text={`${score}/${topicWiseQuestions.length}`}
              styles={buildStyles({ textSize: '24px', pathColor: '#007bff', textColor: '#1DB954', trailColor: '#f0f7ff', pathTransitionDuration: 1 })}
            />
          </div>
          <h2>Congratulations!</h2>
          <button className="continue-button" onClick={() => handleContinue()}>CONTINUE</button>
        </div>
      </CelebrationModal>
      <CelebrationModal isOpen={openTopicsModal} onClose={() => setOpenTopicsModal(false)} animation={false} heading='Select your topic'>
        <div className="button-group">
          {uniqueTopics.map((topic) => (
            <Button key={topic} labelText={topic} handleClick={() => loadTopicQuestions(topic, questions)} classNames={topicWiseQuestions[0]?.topic === topic ? 'btn-active' : ''} />
          ))}
        </div>
      </CelebrationModal>
      <div className='quizz_header'>
        <div className='quizz_header_icon'>Change Your Topic
          <IoMdOpen size={30} color='#fff' cursor={"pointer"} onClick={onOpenSidebar} />
        </div>
        <div className='topic_name'>{topicName}</div>
        <div className='quizz_header_count'>Questions: {currentQuestionIndex + 1}/{topicWiseQuestions.length}</div>
      </div>
      <div className="quizz_container">
        <div className="quizz_container_question">
          {currentQuestion?.question}
        </div>
        <div className="quizz_container_answer_continer">
          {currentQuestion?.options.map((answer, index) => (
            <div className="radio-wrapper-3" key={index}>
              <label htmlFor={`answer-${index}`}>
                <input
                  id={`answer-${index}`}
                  type="radio"
                  name="radio-examples"
                  checked={topicWiseQuestions[currentQuestionIndex]?.selectedAnswer?.id === answer.id}
                  onChange={() => onSelectAnswer(answer)}
                />
                <span className="circle"></span>
                <span style={{ paddingLeft: '1rem' }}>{answer.text}</span>
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="quizz_button_container">
        <Button labelText="prev" minWidth="60px" handleClick={onPrev} disabled={currentQuestionIndex === 0} />
        <Button labelText="next" minWidth="60px" handleClick={onNext} disabled={currentQuestionIndex === topicWiseQuestions.length - 1} />
        <Button labelText="submit" minWidth="60px" handleClick={onSubmit} />
      </div>
    </div>
  );
};

export default KnowledgeCheck;
