import React, { useEffect, useState } from 'react';
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import CodeEditor from './Editor';
import axios from 'axios';

function CodeEditorNew() {

    const [codingQuestions, setCodingQuestions] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [currentQuestion, setCurrentQuestion] = useState<any>(null);
    const [enableEditor, setEnableEditor] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            await axios.get('https://api.zoneofed.com/v1/coding-problems').then((res) => {
                setCodingQuestions(res.data.data[0]);
                setLoading(false)
            }).catch(err => console.log(err))
        }
        fetchData();
    }, []);

    const isEnableEditor = (question: any) => {
        setCurrentQuestion(question);
        setEnableEditor(true);
    }

    const goToProblems = () => {
        setCurrentQuestion(null);
        setEnableEditor(false)
    }

    return (
        <>
            {!loading && (
                <>
                    {enableEditor ? <CodeEditor obj={currentQuestion} goToProblems={goToProblems} /> : (
                        <div className='code-editor_questions_list'>

                            {codingQuestions?.problems.map((code: any) => (
                                <div className='code-editor_questions_list_container'>
                                    <h5>{code?.topic}</h5>
                                    {code.topic_problems.map((question: any) => (
                                        <div className='question-container'>
                                            <div className='question'>
                                                {question.description}
                                            </div>
                                            <div className='goTo_editor_icon'>
                                                <FaRegArrowAltCircleRight
                                                    cursor={"pointer"}
                                                    size={28}
                                                    color='#1DB954'
                                                    onClick={() => isEnableEditor(question)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}

                        </div>
                    )}
                </>
            )}
        </>

    );
}

export default CodeEditorNew;


