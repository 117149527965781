import React, { useState } from "react";
import { FaChevronRight } from "react-icons/fa";

interface AccordionProps {
    id: string,
    title: string,
    content: any
}
function Accordion({ id, title, content }: AccordionProps) {
    const [isOpen, setIsOpen] = useState(true);

    const toggleAccordion = () => setIsOpen(!isOpen);
    return (
        <section className={`accordion ${isOpen ? "active" : ""}`} id={id}>
            <h1 className="title">
                <button onClick={toggleAccordion}>
                    <FaChevronRight
                        style={{
                            transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                            transition: "transform 0.3s",
                        }}
                    />
                    {title}
                </button>
            </h1>
            <div className="content" style={{ maxHeight: isOpen ? "500px" : "0" }}>
                <div className="wrapper">
                    {content}
                </div>
            </div>
        </section>
    )
}

export default Accordion