import React from 'react'
import CourseCardImg from '../assets/course_header.png';
import { FaCalendarAlt } from "react-icons/fa";
import Button from './Button';

interface CourseCardProps {
  title: string,
  content: string,
  duration: string
}
function CourseCard({ title, content, duration }: CourseCardProps) {
  return (
    <div className='course_card'>
      <div className='course_card_img'>
        <img src={CourseCardImg} alt='CourseCardImg' />
      </div>
      <div className='course_card_container'>
        <div className='course_card_container_content'>
          <div className='course_card_container_content_header'>
            <h5>{title}</h5>
            <p>{content}</p>
            <div className='course_card_container_content_duration'>
              <FaCalendarAlt />
              <span>{duration}</span>
            </div>
          </div>

          <Button labelText='course details' color='#1DB954' withIcon />

        </div>
      </div>
    </div>
  )
}

export default CourseCard