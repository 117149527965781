import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import CelebrationModal from '../../components/CustomModal'
import Button from '../../components/Button'

function Popup() {
    const [openModal, setOpenModal] = useState(false);
    const [language, setLanguage] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        setOpenModal(true)
    }, []);

    const onLangChange = useCallback((lang: string) => {
        setLanguage(lang);
        navigate(`/interview-questions/${lang}`)
    }, [language])
    return (
        <CelebrationModal
            isOpen={openModal}
            isClose={false}
            onClose={() => setOpenModal(false)}
            heading='Select your language'
        >
            <div className='button-group'>
                <Button labelText='Python' handleClick={() => onLangChange('python')} />
                <Button labelText='Javascript' handleClick={() => onLangChange('javascript')} />
                <Button labelText='React' handleClick={() => onLangChange('react')} />
            </div>
        </CelebrationModal>
    )
}

export default Popup