export const PythonQuestions = [
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a001",
        "topicId": "101",
        "topic": "Introduction",
        "title": "What is Python?",
        "content": "Python is a high-level, interpreted, and dynamic programming language known for its simplicity and readability."
    },
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a002",
        "topicId": "101",
        "topic": "Introduction",
        "title": "What is Python primarily used for?",
        "content": "Python is used in web development, data analysis, artificial intelligence, scientific computing, automation, and more."
    },
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a003",
        "topicId": "101",
        "topic": "Introduction",
        "title": "Why is Python popular?",
        "content": "Python is popular because of its simple syntax, vast libraries, and strong community support, making it ideal for beginners and experienced developers alike."
    },
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a004",
        "topicId": "102",
        "topic": "Features",
        "title": "What are Python's key features?",
        "content": "Some key features of Python are: interpreted, dynamically-typed, object-oriented, easy to learn and read, has extensive libraries, and supports multiple programming paradigms."
    },
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a005",
        "topicId": "102",
        "topic": "Features",
        "title": "How does Python handle memory management?",
        "content": "Python handles memory management using automatic garbage collection, which reclaims unused memory by removing objects no longer in use."
    },
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a007",
        "topicId": "103",
        "topic": "Coding Standards",
        "title": "What is PEP 8, and why is it important?",
        "content": "PEP 8 is Python's style guide for writing clean and readable code. It defines conventions like naming conventions, line spacing, indentation, and more to promote consistency across Python projects."
    },
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a008",
        "topicId": "103",
        "topic": "Coding Standards",
        "title": "What is PEP 20 (Zen of Python)?",
        "content": "PEP 20, also known as the Zen of Python, is a collection of guiding principles for writing computer programs in Python. It emphasizes simplicity, readability, and the philosophy that 'There should be one—and preferably only one—obvious way to do it.'"
    },
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a009",
        "topicId": "103",
        "topic": "Coding Standards",
        "title": "How can you enforce PEP 8 in your Python project?",
        "content": "PEP 8 can be enforced using tools like `pylint`, `flake8`, or integrated IDE tools that check for PEP 8 compliance."
    },
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a010",
        "topicId": "104",
        "topic": "Functions",
        "title": "What is a Python decorator?",
        "content": "A decorator is a function that adds additional functionality to an existing function or class without modifying its structure. It is commonly used for logging, authentication, or caching."
    },
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a011",
        "topicId": "104",
        "topic": "Functions",
        "title": "What is a lambda function in Python?",
        "content": "A lambda function is an anonymous, small function defined with the `lambda` keyword. It can have any number of arguments but only one expression. Example: `lambda x: x + 1`."
    },
    {
        "id": "8745f94d-34a4-40e5-a3ad-63e9eb5a5322",
        "topicId": "104",
        "topic": "Functions",
        "title": "What is the purpose of the `def` keyword in Python?",
        "content": "The `def` keyword is used to define a function in Python. It marks the beginning of a function block, followed by the function name, parameters, and the function body."
    },
    {
        "id": "d9f2354c-f70a-40a5-b58b-1a2f1bb29e25",
        "topicId": "104",
        "topic": "Functions",
        "title": "What is a higher-order function?",
        "content": "A higher-order function is a function that takes one or more functions as arguments or returns a function as a result. Example: `map()`, `filter()`."
    },
    {
        "id": "fc319d0a-4a2a-49e6-b62f-f8596747f60f",
        "topicId": "104",
        "topic": "Functions",
        "title": "What is the difference between a function and a method in Python?",
        "content": "A function is a block of code defined independently, while a method is a function associated with an object. Methods are called on an object, like `obj.method()`."
    },
    {
        "id": "7a489f6c-2f07-4138-85dc-1af2a022a0f2",
        "topicId": "104",
        "topic": "Functions",
        "title": "What is the `return` statement used for in Python?",
        "content": "The `return` statement is used to exit a function and pass back a value to the caller. If no value is provided, the function returns `None` by default."
    },
    {
        "id": "bc8b7936-66f5-4b7f-9b74-8a9c1ea07c91",
        "topicId": "104",
        "topic": "Functions",
        "title": "Can Python functions have default arguments?",
        "content": "Yes, Python functions can have default arguments. These are specified in the function definition, allowing a default value to be used if no argument is provided."
    },    
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a013",
        "topicId": "105",
        "topic": "Data Types",
        "title": "What are Python's built-in data types?",
        "content": "Python's built-in data types include integers, floats, strings, lists, tuples, sets, and dictionaries."
    },
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a014",
        "topicId": "105",
        "topic": "Data Types",
        "title": "What is the difference between `list` and `tuple` in Python?",
        "content": "The primary difference is that lists are mutable (they can be changed), while tuples are immutable (they cannot be changed). Lists are defined with square brackets `[]` and tuples with parentheses `()`."
    },
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a015",
        "topicId": "105",
        "topic": "Data Types",
        "title": "What is a dictionary in Python, and how do you use it?",
        "content": "A dictionary in Python is a collection of key-value pairs. You can create a dictionary using curly braces `{}` and access values by using the keys. Example: `my_dict['key']`."
    },
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a016",
        "topicId": "106",
        "topic": "Concurrency",
        "title": "What is the Global Interpreter Lock (GIL) in Python?",
        "content": "The GIL is a mutex that protects access to Python objects, preventing multiple threads from executing Python bytecodes at once. This means that Python's multi-threading is limited by the GIL in CPU-bound tasks."
    },
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a017",
        "topicId": "106",
        "topic": "Concurrency",
        "title": "How do you achieve parallelism in Python?",
        "content": "Parallelism in Python can be achieved using the `multiprocessing` module, which bypasses the GIL by creating separate processes that run independently of each other."
    },
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a018",
        "topicId": "106",
        "topic": "Concurrency",
        "title": "What is the difference between threading and multiprocessing in Python?",
        "content": "Threading is used for I/O-bound tasks and shares memory space, while multiprocessing creates separate memory spaces, making it suitable for CPU-bound tasks."
    },
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a019",
        "topicId": "107",
        "topic": "Memory Management",
        "title": "How is memory managed in Python?",
        "content": "Memory management in Python involves a private heap containing all Python objects and data structures. The memory manager handles allocation, while the garbage collector reclaims unused memory using reference counting and cycle detection."
    },
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a020",
        "topicId": "107",
        "topic": "Memory Management",
        "title": "What is garbage collection in Python?",
        "content": "Garbage collection in Python is the process of reclaiming memory by identifying and disposing of objects that are no longer referenced by the program."
    },
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a021",
        "topicId": "107",
        "topic": "Memory Management",
        "title": "What is the `del` keyword in Python, and how does it work?",
        "content": "The `del` keyword in Python is used to delete objects. It can remove variables, list items, or entire objects, but it doesn't free memory immediately—it simply reduces the reference count to the object."
    },
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a022",
        "topicId": "108",
        "topic": "Error Handling",
        "title": "How do you handle exceptions in Python?",
        "content": "In Python, exceptions are handled using `try`, `except`, `finally`, and `else` blocks. Code that might raise an exception is placed in the `try` block, and the `except` block catches the exception to handle it gracefully."
    },
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a023",
        "topicId": "108",
        "topic": "Error Handling",
        "title": "What are common exceptions in Python?",
        "content": "Common exceptions in Python include `ValueError`, `TypeError`, `IndexError`, `KeyError`, `IOError`, and `ZeroDivisionError."
    },
    {
        "id": "35c2078d-6d72-43ed-b086-32e9aa98a024",
        "topicId": "108",
        "topic": "Error Handling",
        "title": "What is the difference between an exception and a syntax error in Python?",
        "content": "An exception occurs when an error is detected during execution, while a syntax error is raised when the parser encounters code that violates Python's syntax."
    }
];
