import React from 'react'
import { FaArrowRight } from "react-icons/fa";

interface ButtonProps {
  classNames?: string,
  labelText: string,
  color?: string,
  withIcon?: boolean,
  minWidth?: string,
  disabled?: boolean,
  handleClick?: () => void
}
function Button({
  labelText,
  classNames,
  color = '#fff',
  withIcon = false,
  minWidth = '120px',
  disabled = false,
  handleClick
}: ButtonProps) {
  return (
    <button
      className={`custom_button ${classNames}`}
      onClick={handleClick}
      disabled={disabled}
      style={{ "--button-color": color, '--button-min-width': minWidth } as React.CSSProperties}
    >
      {labelText}
      {withIcon && <FaArrowRight size={'0.9em'} />}
    </button>
  )
}

export default React.memo(Button)