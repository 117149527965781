import React, { useState } from 'react'
import logoImg from '../assets/logo.png';
import { HiMenu } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import Link from './Link';
import { NavLink } from 'react-router-dom';

function Header() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    return (
        <>
            <div className='header'>
                <div className='header_logo'>
                    <NavLink to={'/'}><img src={logoImg} alt='logoImg' /></NavLink>
                </div>
                <nav className='header_links'>
                    <Link labelText='code editor' withLink to='/editor' />
                    <Link labelText='interview quesiton' withLink={true} to='/interview-questions' />
                    <Link labelText="Knowledge check" withLink={true} to='/knowledge-check' />
                </nav>
                <button
                    className="hamburger-button"
                    onClick={toggleSidebar}
                    aria-label="Toggle menu"
                >
                    <HiMenu size={24} />
                </button>
            </div>
            {/* Mobile Sidebar */}
            <div className={`sidebar ${isSidebarOpen ? 'sidebar--open' : ''}`}>
                <div className="sidebar-content">
                    <button
                        className="sidebar-close"
                        onClick={toggleSidebar}
                        aria-label="Close menu"
                    >
                        <IoMdClose size={24} />
                    </button>

                    <nav className="sidebar-nav">
                        <Link labelText="code editor" withLink to='/editor' handleClick={() => setIsSidebarOpen(false)} />
                        <Link labelText="interview question" withLink={true} to='/interview-questions' handleClick={() => setIsSidebarOpen(false)} />
                        <Link labelText="Knowledge check" withLink={true} to='/knowledge-check' handleClick={() => setIsSidebarOpen(false)} />
                    </nav>
                </div>
            </div>

            {/* Overlay */}
            {isSidebarOpen && (
                <div
                    className="sidebar-overlay"
                    onClick={toggleSidebar}
                />
            )}
        </>
    )
}

export default Header