import React from 'react'
import CourseCard from '../../components/CourseCard'
import { FeatureCards } from '../../data/FeatureCards'

function FeatureCourses() {
    return (
        <div className='feature_courses'>
            <h1>Featured Courses</h1>
            <div className='feature_courses_cards'>
                {FeatureCards.map(({ title, content, duration }, index) => (
                    <CourseCard title={title} content={content} duration={duration} key={index} />
                ))}
            </div>
            {/* <div className='feature_course_button'>
                <Button labelText='view courses' />
            </div> */}
        </div>
    )
}

export default FeatureCourses