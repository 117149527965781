import React from 'react'
import Link from '../../components/Link'

const footer_data = [
    {
        heading: 'Quick Links',
        values: [
            { label: 'About Us', withLink: true, to: '/about' },
            { label: 'Why Us', withLink: true, to: '/whyus' }
        ]
    },
    {
        heading: 'Trending Courses',
        values: [
            { label: 'Web Development' },
            { label: 'MERN Stack' },
            { label: 'Python' },
            { label: 'Dot net' }
        ]
    },
    {
        heading: 'Interview Questions',
        values: [
            { label: 'JavaScript', withLink: true, to: '/interview-questions/javascript' },
            { label: 'React', withLink: true, to: '/interview-questions/react' },
            { label: 'Python', withLink: true, to: '/interview-questions/python' },
        ]
    },
    {
        heading: 'Contact Us',
        values: [
            { label: '+(91)-812-141-9550' },
            { label: '+(91)-812-141-9550' },
        ]
    }
]
function Footer() {
    return (
        <div className='footer'>
            <div className="footer_container">
                {footer_data.length && footer_data.map(({ heading, values }, index) => (
                    <div className='footer_container_contentBox' key={`${heading}_${index}`}>
                        <h5>{heading}</h5>
                        <p>
                            {values.length && values.map(({ label, withLink = false, to = null }: any, index) => (
                                <Link key={`${label}_${index}`} labelText={label} withLink={withLink} to={to} />
                            ))}
                        </p>
                    </div>
                ))}
                <div className='footer_container_copyright'>© ZoneOfEd | Mastery in Programming Courses 2023</div>
            </div>
        </div>
    )
}

export default Footer