export const FeatureCards = [
    {
      title: 'React Js',
      content: 'Learn the fundamentals of React, including component-based architecture and state management. Build dynamic web applications with ease.',
      duration: '2 Months'
    },
    {
      title: 'Python',
      content: 'Master Python programming with hands-on projects. Learn data structures, algorithms, and libraries like Pandas and NumPy.',
      duration: '4 Months'
    },
    {
      title: 'MERN',
      content: 'Dive into full-stack development with MongoDB, Express, React, and Node.js. Create scalable web applications from scratch.',
      duration: '6 Months'
    }
  ]
  