import React from 'react'
import { NavLink } from 'react-router-dom';

interface LinkProps {
    withLink?: boolean,
    labelText: string,
    to?: string,
    handleClick?: () => void
}
function Link({ withLink = false, labelText, to, handleClick }: LinkProps) {
    return (
        <div className='custom_link'>
            {withLink && to ? <NavLink to={to} onClick={handleClick} >{labelText}</NavLink> : <span>{labelText}</span>}
        </div>
    )
}

export default Link