import React from 'react';
import Confetti from 'react-confetti';
import { IoMdClose } from 'react-icons/io';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CelebrationModal = ({
    isOpen,
    onClose,
    isClose = true,
    heading = '',
    children,
    animation = false
}: any) => {
    if (!isOpen) return null;

    const handleOverlayClick = (e: React.MouseEvent) => {
        if ((e.target === e.currentTarget) && isClose) {
            onClose();
        }
    };

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                recycle={true}
                run={animation}
            />

            <div className="modal-container">
                <div className='modal-header'>
                    <h1>{heading}</h1>
                    {isClose && (
                        <button className="close-button" onClick={onClose}>
                            <IoMdClose />
                        </button>
                    )}

                </div>

                {/* <div className="content">
                    <div className='content_score'>Your score</div>
                    <div className="progress-circle">
                        <CircularProgressbar
                            value={percentage}
                            text={`${score}/${total}`}
                            styles={buildStyles({
                                textSize: '24px',
                                pathColor: '#007bff',
                                textColor: '#1DB954',
                                trailColor: '#f0f7ff',
                                pathTransitionDuration: 1
                            })}
                        />
                    </div>
                    <h2>{message}</h2>
                    <button className="continue-button" onClick={onClose}>
                        CONTINUE
                    </button>
                </div> */}
                {children}
            </div>
        </div>
    );
};

export default CelebrationModal;