import React from 'react'
import userImg from '../../assets/user.png';

function About() {
    return (
        <div className='about_us'>
            <h1>About Us</h1>
            <div className='about_us_container'>
                <div className='about_us_container_image'>
                    <img src={userImg} alt='userImg' />
                </div>
                <div className='about_us_container_content'>
                    <p>With a decade of experience in e-learning, we specialize in bridging the gap between technology and education.
                        Our journey began with a passion for creating impactful, interactive training platforms that transform the learning
                        experience. Leveraging our expertise in JavaScript, React, Node.js, and Python, we build comprehensive web
                        applications that meet the dynamic needs of modern learners and educators alike. We understand the nuances of
                        online training and are dedicated to developing solutions that empower users to learn, grow, and succeed.
                    </p>
                    <p>
                        Our recent venture into developing our own website embodies our commitment to continuous improvement and innovation,
                        demonstrating our capability to manage projects from concept to completion. We are not just developers;
                        we are dedicated partners in your journey to achieving seamless and efficient e-learning experiences.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default About