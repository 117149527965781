import './styles/index.scss';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from './components/Header';
import Home from './pages/Home';
import Layout from './pages/Layout';
import InterviewQuestions from './pages/InterviewQuestions';
import CodeEditor from './pages/CodeEditor';
import KnowledgeCheck from './pages/Quizz';
import About from './pages/About';
import WhyUs from './pages/About/Why';
import Popup from './pages/InterviewQuestions/Popup';
import CodeEditorNew from './pages/CodeEditorNew';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Layout><Home /></Layout>} />
          <Route path="/interview-questions/:id" element={<Layout><InterviewQuestions /></Layout>} />
          <Route path="/interview-questions" element={<Layout><Popup /></Layout>} />
          {/* <Route path="/editor" element={<Layout><CodeEditor /></Layout>} /> */}
          <Route path="/knowledge-check" element={<Layout><KnowledgeCheck /></Layout>} />
          <Route path="/about" element={<Layout><About /></Layout>} />
          <Route path="/whyus" element={<Layout><WhyUs /></Layout>} />
          <Route path="/editor" element={<Layout><CodeEditorNew /></Layout>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
