import React from 'react'
import valuesLogo from '../../assets/values_logo.png';

function Values() {
    return (
        <div className='values_page'>
            <div className='values_page_image'>
                <img src={valuesLogo} alt='valuesLogo' />
            </div>
            <div className='values_page_content'>
                <h2>Our Values</h2>
                <p>We firmly believe that knowledge is power and sharing knowledge occurs
                    when people are truly interested in learning and helping each other inculcate
                    and develop new capacities for action and this is how we define the learning
                    process at <span>Zoneofed</span>.</p>
            </div>
        </div>
    )
}

export default Values