import React, { useEffect, useRef, useState } from 'react';
import { Editor, OnMount } from "@monaco-editor/react";
import { DropdownList } from 'react-widgets/cjs';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { IoArrowBackCircle } from "react-icons/io5";
import { executeCode } from '../CodeEditor/api';
import { CODE_SNIPPETS, SELECTED_LANGUAGE } from '../CodeEditor/constants';

interface CodeEditorProps {
    obj: {
        description: string;
        examples: Example[];
        code: string;
    };
    goToProblems: () => void;
}

interface Example {
    input: string;
    output: string;
    explanation?: string;
}

function CodeEditor({ obj, goToProblems }: CodeEditorProps) {
    const [activeTab, setActiveTab] = useState<'description' | 'solution'>('description');
    const [value, setValue] = useState<string>(CODE_SNIPPETS[SELECTED_LANGUAGE]);
    const editorRef = useRef<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [language, setLanguage] = useState<string>(SELECTED_LANGUAGE);
    const [output, setOutput] = useState<string | null>(null);

    const onMount: OnMount = (editor) => {
        editorRef.current = editor;
        editor.focus();
    };

    useEffect(() => {
        if (language === 'javascript') {
            setValue(CODE_SNIPPETS['javascript'])
        } else {
            setValue(CODE_SNIPPETS['python'])
        }
    }, [language]);

    const runCode = async () => {
        const sourceCode = editorRef.current?.getValue();
        if (!sourceCode) return;

        try {
            setIsLoading(true);
            const result = await executeCode(language, sourceCode);
            setOutput(result.run.output);
        } catch (error) {
            console.error("Error executing code", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='code-editor'>
            <div className='practice_container_questions'>
                <div style={{ padding: '1rem' }}>
                    <div className="practice_container_questions_header-tabs">
                        <div className="tabs">
                            <IoArrowBackCircle size={28} onClick={goToProblems} cursor='pointer' />
                            <button
                                className={`tab ${activeTab === 'description' ? 'active' : ''}`}
                                onClick={() => setActiveTab('description')}
                            >
                                Description
                            </button>
                            <button
                                className={`tab ${activeTab === 'solution' ? 'active' : ''}`}
                                onClick={() => setActiveTab('solution')}
                            >
                                Solution
                            </button>
                        </div>
                    </div>
                    {activeTab === 'description' && (
                        <div className="practice_container_questions_description_tab">
                            <h4>Topic: print</h4>
                            <p>{obj.description}</p>
                            {obj.examples.length && obj.examples.map((example, index) => (
                                <div key={index}>
                                    <h3><b>Example {index + 1}</b></h3>
                                    <ExampleSolution example={example} />
                                </div>
                            ))}
                        </div>
                    )}
                    {activeTab === 'solution' && (
                        <div className='practice_container_questions_solution_tab'>
                            <div className='practice_container_questions_solution_tab_code'>
                                <h4>Code:</h4>
                                <SyntaxHighlighter language="javascript" style={a11yDark}>
                                    {obj.code}
                                </SyntaxHighlighter>
                            </div>
                            <div className='practice_container_questions_solution_tab_link'>
                                <h4>Solution link:</h4>
                                {/* Add link here if available */}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='practice_container_editor'>
                <div className="practice_container_editor_header">
                    <div className='dropdown'>
                        <DropdownList
                            defaultValue="Python"
                            data={["Javascript", "Python"]}
                            style={{ minWidth: '150px', textAlign: 'left' }}
                            onChange={(value) => setLanguage((value as string).toLowerCase())}
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        {isLoading && <div className='spinner' />}
                        <button className='run-code' onClick={runCode}>Run Code</button>
                    </div>
                </div>
                <div className="practice_container_editor_box">
                    <Editor
                        language={language}
                        onMount={onMount}
                        value={value}
                        onChange={(newValue) => setValue(newValue || "")}
                        height="100%"
                        defaultLanguage="javascript"
                        defaultValue="// Write your code here"
                    />
                </div>
                <div className="practice_container_editor_output">
                    {(!isLoading && output) ? output : "Click Run Code to see the output here"}
                </div>
            </div>
        </div>
    );
}

export default CodeEditor;

const ExampleSolution: React.FC<{ example: Example }> = ({ example }) => {
    return (
        <div className='example_solution'>
            <div className='example_solution_input'>
                <b>Input:</b>
                <div dangerouslySetInnerHTML={{ __html: example.input }} />
            </div>
            <div className='example_solution_output'>
                <b>Output:</b>
                <div dangerouslySetInnerHTML={{ __html: example.output }} />
            </div>
            {example.explanation && (
                <div className='example_solution_explanation'>
                    <b>Explanation:</b>
                    <span>{example.explanation}</span>
                </div>
            )}
        </div>
    );
};
