import React from 'react'

function WhyUs() {
    return (
        <div className='why_us'>
            <h1>why Us</h1>
            <div className='why_us_container'>
                <div className='why_us_container_content'>
                    <ul>
                        <li>
                            <span>Proven Track Record in E-Learning : </span>
                            With 10 years in the e-learning industry, we’ve developed a deep understanding of effective digital learning.
                            Our experience enables us to create training platforms that prioritize engagement,
                            retention, and user-centric design, setting learners up for success.
                        </li>
                        <li>
                            <span>Technical Expertise Across All Layers : </span>
                            As a full-stack developer with skills in JavaScript, React, Node.js, and Python, we offer an all-encompassing approach.
                            From front-end interfaces to back-end systems,
                            we ensure each part of the application works in harmony for optimal performance and a smooth user experience.
                        </li>
                        <li>
                            <span>Passionate About Empowering Learners : </span>
                            Beyond technical skills, our true passion lies in empowering learners and educators by simplifying complex technology.
                            We’re here to support your mission to make learning accessible, engaging, and impactful.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default WhyUs