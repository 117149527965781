// BenefitsSection.tsx
import React from 'react';
import { BiBook, BiRocket, BiCode, BiGlobe } from 'react-icons/bi';

type BenefitCardProps = {
    icon: React.ComponentType; // To define a React component as a prop
    title: string;
    description: string;
};

const BenefitCard: React.FC<BenefitCardProps> = ({ icon: Icon, title, description }) => (
    <div className="benefits__card">
        <div className="benefits__card-content">
            <div className="benefits__card-icon">
                <Icon />
            </div>
            <div className="benefits__card-text">
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </div>
    </div>
);

type Benefit = {
    icon: React.ComponentType;
    title: string;
    description: string;
};

const BenefitsSection: React.FC = () => {
    const benefits: Benefit[] = [
        {
            icon: BiBook,
            title: "Knowledge",
            description:
                "Joining us means starting an incredible quest to seek knowledge and practice learning by working on brilliant projects that are a gateway to hands-on experience and industry-readiness.",
        },
        {
            icon: BiRocket,
            title: "Confidence",
            description:
                "In our early days as developers, we have come across many students who are sharp yet confused and under-confident making it as a developer or even learning coding.",
        },
        {
            icon: BiCode,
            title: "Interview-ready",
            description:
                "How does one get ready for an interview? The answer is: Knowledge, awareness of best practices, hands-on experience and confidence. That is exactly what we aim at!",
        },
        {
            icon: BiGlobe,
            title: "One stop destination for resources",
            description:
                "At ZoneofEd, we do not just strive towards delivering the best content, we aim to make your learning more impactful and sustainable. For this reason, from experience, exposure and expertise.",
        },
        {
            icon: BiGlobe,
            title: "Hands-on Experience",
            description:
                "Our delivery of knowledge does not just end in a classroom environment. It goes all the way to working on exciting real world projects, participating in inspiring coding challenges and learning as a group",
        },
    ];

    return (
        <section className="benefits">
            <div className="benefits__header">
                <h2>Benefits of joining Zone Of Ed</h2>
                <p>
                    You may join us to just learn coding. But we aim to build a confident and knowledgeable professional who is ready to take off.
                </p>
            </div>

            <div className="benefits__grid">
                {benefits.map((benefit, index) => (
                    <BenefitCard
                        key={index}
                        icon={benefit.icon}
                        title={benefit.title}
                        description={benefit.description}
                    />
                ))}
            </div>
        </section>
    );
};

export default BenefitsSection;
